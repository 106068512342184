@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';

.opening-hours-group {
  width: 100%;
  margin-bottom: spacing.$xxl;
  font-variant-numeric: tabular-nums;

  &__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing.$m;
  }

  &__modal-text {
    &:not(:last-child) {
      margin-bottom: spacing.$l;
    }
  }

  &__modal-concept {
    margin-right: spacing.$xs;
  }

  &__empty-state {
    margin-top: spacing.$l;
    color: lightmode.$on-surface-secondary;
  }

  &__links {
    display: flex;
    gap: spacing.$s;
    margin-bottom: spacing.$m;
  }

  &__time-cards {
    > * + * {
      margin-top: spacing.$m;
    }
  }
}
